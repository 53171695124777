import { createAsyncThunk } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
export const getMateriaux = createAsyncThunk('materiaux/getMateriaux', async ({ search, limit, page, archived, type, }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search ? { limit, page, archived, type } : { search, limit, page, archived, type });
        const response = await apiKey.get(`/materials?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createMateriaux = createAsyncThunk('materiaux/createMateriaux', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/materials`, serialize({ ...params }));
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateMateriaux = createAsyncThunk('materiaux/updateMateriaux', async ({ params, id }, ThunkApi) => {
    if (typeof params.file === 'string')
        delete params?.file;
    try {
        const response = await apiKey.put(`/materials/${id}`, serialize({ ...params }));
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getMaterialById = createAsyncThunk('materiaux/getMaterialById', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/materials/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteMateriaux = createAsyncThunk('materiaux/deleteMateriaux', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.delete(`/materials/${id}`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const archiveMateriaux = createAsyncThunk('patient/archiveMateriaux', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.post(`/materials/${id}/archive`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const unArchiveMateriaux = createAsyncThunk('patient/unArchiveMateriaux', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.post(`/materials/${id}/unarchive`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
